li{
    list-style: none;

}

body{
    
    background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
    background-position: center -8px;
    background-color: aliceblue;
}
#hnav-bar
{
    /* display: flex;
    justify-content: space-between; */
    padding: 0 40px;

}

.hlink
{
    width: 120px;
    height: 40px;
    background-color: rgb(80, 82, 242);
    border-radius: 20px;
   /* border: 2px solid black; */
    margin-right: 20px;
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
   

}
#hlinks
{
    display: flex;
 
    margin-top: 28px;
}
.headcolor
{
 

    color: rgb(18, 18, 112);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

h2{
    margin-top: 0px;
}
p{
    margin-top: 150px;
}
h2{
    padding-top: 100px;
}
.navcolor
{
    padding: 5px;
    font-weight:600;
   font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
.hfont{
    font-size:25px;
    color:black;
    font-family:monospace;
    background-color: wheat;
    color:rgba(37, 18, 112, 0.89); ;    
    box-shadow: 4px 10px 24px black;
    border-radius: 50px;
    margin-top: 150px;
    
    padding: 30px;
   width: 290px;
   margin: auto;

}
.pfont
{
    background-color: rgba(37, 18, 112, 0.89);
    color:wheat;
   border-radius: 50px;
   box-shadow: 4px 10px 24px black;
    font-size:40px;
     font-family:monospace;
    padding:20px;
    margin:15px;
    margin-top: 100px;
    
}
.hcont
{
    margin-top: 150px;
}

@media screen  and  (max-width :320px) {
    #pprofile{
        display:block;
        box-sizing: border-box;
        width: 100vw;
    }
    #hlinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin:auto;

    }

    #hhead
    {
        display: block;
    
    }
    #hnav-bar
    {
        display: block;
    }

.inlinelink
{
    font-size: 18px;
}
    #phead
{
    font-size: 50px;
}

.hfont
{
    font-size: 18px;
}
.pfont{
    font-size: 20px;
    padding: 30px;
    margin: 50px;
}

    body{
    
        background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
        background-position: center 25px;
        background-color: aliceblue;
    }
}


@media screen and (min-width:320px) and (max-width:430px) {
    #pprofile{
        display:block;
        box-sizing: border-box;
        width: 100vw;
    }
    #hlinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin:auto;

    }

    #hhead
    {
        display: block;
    
    }
    #hnav-bar
    {
        display: block;
    }

.inlinelink
{
    font-size: 18px;
}
    #phead
{
    font-size: 50px;
}

.hfont
{
    font-size: 18px;
}
.pfont{
    font-size: 20px;
    padding: 30px;
    margin: 50px;
}

    body {
        background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
        background-position: center 25px;
        background-color: aliceblue;
    }

}




@media screen and (min-width: 430px) and (max-width:600px)
{
   




    #pprofile{
        display:block;
        box-sizing: border-box;
        width: 100vw;
    }
    #hlinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin:auto;

    }

    #hhead
    {
        display: block;
    
    }
    #hnav-bar
    {
        display: block;
    }

.inlinelink
{
    font-size: 18px;
}
    #phead
{
    font-size: 50px;
}

.hfont
{
    font-size: 18px;
}
.pfont{
    font-size: 20px;
    padding: 30px;
    margin: 50px;
}

body{
    
    background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
    background-position: center 10px;
    background-color: aliceblue;
}





}

@media screen and (min-width: 600px) and (max-width: 750px) {
    #dprofile
    {
        display: grid;
        grid-template-rows: 1fr 1fr ;
        grid-template-columns: 1fr 1fr ;
        width: 100vw;
    }
    #plinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin:auto;
    }
    #hnav-bar
    {
        display: flex;
       justify-content: space-between;
    }
    #hhead
    {
        display: flex;
        margin-top: 25px;
    }
    body{
    
        background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
        background-position: center -3px;
        background-color: aliceblue;
    }

}
@media screen and (min-width: 750px)  {
    #pprofile
    {
        display: grid;
        width: 100vw;
        grid-template-rows: 1fr 1fr 1fr ;
        grid-template-columns: 1fr 1fr 1fr ;
    
    }
    #hnav-bar
    {
        display: flex;
        justify-content: space-between;
    }
    #hhead
    {
        display: flex;
        margin-top: 25px;
     
      
        height: 30px;
    }
    #plinks
    {
        margin-right: 20px;
        margin:auto;
    }
    body{
    
        background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
        background-position: center -79px;
        background-color: aliceblue;
    }
   
}