



h1{
    margin-top: 40px;
 
}
#phead
{
    font-size: 80px;
}

.plink
{
    width: 220px;
 padding: 10px;
    background-color: rgb(80, 82, 242);
    border-radius: 20px;
   /* border: 2px solid black; */
    margin-right: 20px;
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
 padding-top: 10px;

}
#plinks
{
  margin-top: 50px;
    margin-left: 470px;
    color: aliceblue;
    display: flex;
    justify-content: center;
 
    margin:auto;
}

.pprofile
{
  width: 300px;
    border: 2px solid black;
    margin: 20px;
    border-radius: 20px;
    background-color: rgba(37, 18, 112, 0.89);
    color: white;
    width: 200px;
    margin: 50px auto ;
}
#pcont
{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 80px;

}

.inlinelink
{
    font-size: 20px;
    padding: 5px;
    font-weight:600;
   font-family: cursive;

}





@media  (max-width:530px)
{
    #pprofile{
        display:block;
        box-sizing: border-box;
        width: 100vw;
    }
  
    #plinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin:auto;
        
    }

.inlinelink
{
    font-size: 16px;
}
    #phead
{
    font-size: 50px;
}


}

@media screen and (min-width: 530px) and (max-width: 750px) {
    #dprofile
    {
        display: grid;
        grid-template-rows: 1fr 1fr ;
        grid-template-columns: 1fr 1fr ;
        width: 100vw;
    }
    #plinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin:auto;
    }

}
@media screen and (min-width: 750px) and (max-width: 850px) {
    #pprofile
    {
        display: grid;
        width: 100vw;
        grid-template-rows: 1fr 1fr 1fr ;
        grid-template-columns: 1fr 1fr 1fr ;
    
    }
    #plinks
    {
        margin-right: 20px;
        margin:auto;
    }
   
}