

.App
{
    width: 100%;
}

h1{
    margin-top: 10px;
 
}
#dhead
{
    font-size: 80px;
}

.dlink
{
    width: 120px;
    height: 40px;
    background-color: rgb(80, 82, 242);
    border-radius: 20px;
   /* border: 2px solid black; */
    margin-right: 20px;
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
 padding-top: 10px;

}
#dlinks
{
  margin-top: 50px;
    margin:auto;
    color: aliceblue;
    display: flex;
 justify-content: center;

}

.dprofile
{
    color: rgb(212, 232, 250);
    width:300px;
   
    border-radius: 20px;
    box-shadow: 8px 8px 18px black;
    background-color:  rgba(37, 18, 112, 0.89);
}
#dcont
{
   
    display: grid;
    width: 100vw;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
   
  grid-gap: 40px;

}
@media  (max-width:530px)
{
    
    .dprofile{
        margin: auto;
    }
    #dcont{
       
        display:block;
        box-sizing: border-box;
        width: 100vw;
       
    }
    #dlinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    #dhead
{
    font-size: 50px;
}


}


@media screen and (min-width: 530px) and (max-width: 750px) {
    #dcont
    {
        margin-top: 30px;
        display: grid;
        grid-template-rows: 1fr 1fr ;
        grid-template-columns: 1fr 1fr ;
        width: 100vw;
    }
    .dprofile{
        margin: auto;
    }
    #dlinks
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

}
@media screen and (min-width: 750px)  {
    #dcont
    {
        margin: auto;
        margin-top: 30px;
        display: grid;
        width: 100vw;
        grid-template-rows: 1fr 1fr 1fr ;
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    
    }
    .dprofile{
        margin: auto;
    }
    #dlinks
    {
        margin-right: 20px;
        margin:auto;
    }
   
}