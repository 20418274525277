#nav-bar
{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
   


}

.link
{
    width: 120px;
    height: 40px;
    background-color: rgb(80, 82, 242);
    border-radius: 20px;
   /* border: 2px solid black; */
    margin-right: 20px;
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
   

}
#links
{
    display: flex;
 
    margin-top: 28px;
}
p{
    margin-top: 0px;
}


#registercontainer
{
    color: aliceblue;
    border: 2px solid black;
    width: 300px;
    height:450px;
    margin: auto;
    line-height: 50px;
    margin-bottom: 20px;
    border-radius: 25px;
    background-color: rgba(37, 18, 112, 0.89);
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
    border: 0  ;
}
input{
    height: 30px;
    width: 200px;
    border-radius: 20px;
    border: 0;
    padding-left: 10px;
}
#maincont
{
    padding-top: 50px;
    background-color: aliceblue;
    height: 100vh;
   
}
#sign{
    margin-top: 0;
    color: white;
   text-shadow: 4px 3px 4px  rgb(0, 0, 0);
}
.signlink
{
    background-color:aliceblue;
    width: 90px;
    height: 30px;
    margin: auto;
    padding-top: 5px;
    border-radius: 20px;
}