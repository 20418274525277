#nav-bar
{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
   


}

.link
{
    width: 120px;
    height: 40px;
    background-color: rgb(80, 82, 242);
    border-radius: 20px;
   /* border: 2px solid black; */
    margin-right: 20px;
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
   

}
#links
{
    display: flex;
 
    margin-top: 28px;
}
p{
    margin-top: 0px;
}


#logincontainer
{
   
    

    color: aliceblue;
    border: 2px solid black;
    width: 300px;
    height:250px;
    margin: auto;
    line-height: 50px;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: rgba(37, 18, 112, 0.89);
    box-shadow: 1px 6px 15px rgb(38, 31, 12);
    border: 0;
}
input{
    height: 30px;
    width: 200px;
    border-radius: 5px;
    border: 0;
}
#maincont
{
    padding-top: 50px;


    height: 100vh;
    background-image:url('https://www.tensorflow.org/static/site-assets/images/marketing/learn/learn-lite-hero.svg');
    background-position: center -155px;
}
#sign{
    margin-top: 0;
}
.button
{
    width: 120px;
    height: 35px;
    border-radius: 10px;
    background-color: rgb(187, 210, 255);
    box-shadow: 1px 3px 8px rgb(38, 31, 12);
    color: black;
}
